export const configuration = {
  cedApiKey: "10a2d196801f886dbb0c43ac6a21783379fe1c52917c162645d204d18ddc4d83b0f4adb99ab632300e41354a7ace53c8f022",
  environment: "production",
  version: "1.0.24",
  backendApi: {
    url: "https://api.customer-support-tool-prod.forge.gg",
    avatarUrl: "https://forge.gg/avatar/",
  },
  userRegistry: {
    domain: "forge-support.us.auth0.com",
    managementClientId: "fDWvc8zsxIFx6IbYFDbIduIJXvTW77mQ",
    managementClientSecret: "LjrTfmMQ_yFdqixjdm-WXSrHj8dbrcFZ6FhgavZ6jxZ2fMBsjNsBfZ9GOL3tMnsK",
    clientId: "DLn59Srp8ybzBDX3EMqRh1zHGtYtI5vV",
    audience: "https://forge-support.us.auth0.com/api/v2/",
  },
};